import { Component, EventEmitter, OnInit } from "@angular/core";
import { AuthService } from "@services/auth.service";
import { LoadingBarService } from "@services/loading-bar.service";
import * as Moment from "moment";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public loadingStatus$ = new EventEmitter<boolean>(true);

  public title = "guttman";

  constructor(
    public readonly loadingBarService: LoadingBarService,
    private readonly authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.loadingBarService.loadingStatus$.subscribe((value) => this.loadingStatus$.emit(value));
    this.authService.$refreshAt.subscribe((value) => {
      if (!value) {
        return;
      }
      const executesIn = Moment.utc(value).diff(Moment.utc()) - 180000;
      setTimeout(
        () => this.authService.refreshToken().subscribe(),
        executesIn > 0 ? executesIn : 0,
      );
    });
  }
}
