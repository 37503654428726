import * as R from "ramda";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@services/auth.service";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

/**
 * This Interceptor is the first handler in the interception barrel
 *
 * This handles errors, and client-facing interactions, such as:
 *  - The loading bar,
 *  - The HTTP error handling
 */
@Injectable({
  providedIn: "root",
})
export class BaseInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.appendAuthHeaderToRequest(next, request).pipe(
      map((event) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        if (R.includes(error.status, [401, 403, 0])) {
          this.authService.logout();
          this.router.navigateByUrl("/auth/login");
          // this.authService.refreshToken().subscribe(() => next.handle(request));
        }
        return throwError(error);
      }),
    );
  }

  private appendAuthHeaderToRequest(
    next: HttpHandler,
    request: HttpRequest<any>,
  ): Observable<HttpEvent<any>> {
    if (request.headers.has("authorization")) {
      return next.handle(request);
    }

    let setHeaders: any = {
      ApiKey: environment.apiKey,
    };

    const token = R.path(["authUser", "token"], this.authService);

    if (token) {
      setHeaders["token"] = token;
    }

    request = request.clone({ setHeaders });

    return next.handle(request);
  }
}
