import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIRoutes } from '@models/api-routes.models';
import { DatatableCategoryData } from '@models/category.models';
import { DatatableTask, DatatableTaskData } from '@models/task.models';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private readonly routes = new APIRoutes();

  public set storedTask(task: DatatableTaskData) {
    localStorage.setItem('storedTask', task ? JSON.stringify(task) : '');
  }

  public get storedTask(): DatatableTaskData {
    const task = localStorage.getItem('storedTask');
    return task ? (JSON.parse(task) as DatatableTaskData) : null;
  }

  constructor(private readonly http: HttpClient) {}

  public list({ name, type, ids, category_id, page, limit}: { name?: string; type?: string; ids?: number[]; category_id?: number; page: number; limit: number; }): Observable<DatatableTask> {
    const params: any = {
      limit: `${limit}`,
      page: `${page}`,
    };
    if (category_id) {
      params.category_id = category_id;
    }
    if (type) {
      params.type = type;
    }
    if (name) {
      params.name = name;
    }
    if (ids) {
      params.ids = ids.join(',');
    }
    return this.http.get<DatatableTask>(this.routes.Tasks.All, { params });
  }

  public get(
    id: number,
  ): Observable<{ task: DatatableTaskData; categories: DatatableCategoryData[] }> {
    return this.http.get<{ task: DatatableTaskData; categories: DatatableCategoryData[] }>(
      this.routes.Tasks.Entity(id),
    );
  }

  public store(payload: any, categories: number[]): Observable<void> {
    return this.http
      .post<any>(this.routes.Tasks.All, payload)
      .pipe(tap(({ id }) => this.addCategories(id, categories)));
  }

  public update(
    id: number,
    payload: any,
    categories: number[],
    deletedCategories: number[],
  ): Observable<void> {
    return this.http.put<void>(this.routes.Tasks.Entity(id), payload).pipe(
      map(() => this.deleteCategories(id, deletedCategories)),
      map(() => this.addCategories(id, categories)),
      map(() => null),
    );
  }

  public deleteCategories(taskId: number, ids: number[]): any {
    return forkJoin(
      ids.map((categoryId) =>
        this.http.delete<void>(this.routes.TaskCategories.Custom([`${categoryId}`, `${taskId}`])),
      ),
    ).subscribe();
  }

  public addCategories(taskId: number, ids: number[]): any {
    return forkJoin(
      ids.map((categoryId) =>
        this.http.post<void>(this.routes.TaskCategories.Custom([`${categoryId}`, `${taskId}`]), {}),
      ),
    ).subscribe();
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(this.routes.Tasks.Entity(id));
  }
}
