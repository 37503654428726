import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIRoutes } from '@models/api-routes.models';
import {AppPayload, DatatableApp, DatatableAppData} from '@models/app.models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppsService {
  private readonly routes = new APIRoutes();

  public set storedApp(app: DatatableAppData) {
    localStorage.setItem('storedApp', app ? JSON.stringify(app) : '');
  }

  public get storedApp(): DatatableAppData {
    const app = localStorage.getItem('storedApp');
    return app ? (JSON.parse(app) as any) : null;
  }

  constructor(private readonly http: HttpClient) {}

  public list({ search }: { search: string }): Observable<DatatableAppData[]> {
    return this.http.get<any>(this.routes.Apps.All, {
      params: { search },
    });
  }

  public get(id: number): Observable<DatatableAppData> {
    return this.http.get<any>(this.routes.Apps.Entity(id));
  }

  public store(payload: AppPayload): Observable<any> {
    return this.http.post<void>(this.routes.Apps.All, payload);
  }

  public storeTasks(appId: number, taskIds: number[]): Observable<any> {
    return this.http.post<void>(this.routes.Apps.Custom([`${appId}`, 'tasks']), taskIds);
  }

  public removeTasks(appId: number, taskIds: number[]): Observable<any> {
    return this.http.request<void>('DELETE', this.routes.Apps.Custom([`${appId}`, 'tasks']), {
      body: taskIds,
    });
  }

  public update(id: number, payload: AppPayload): Observable<any> {
    return this.http.put<void>(this.routes.Apps.Entity(id), payload);
  }

  public delete(id: number): Observable<any> {
    return this.http.delete<void>(this.routes.Apps.Entity(id));
  }
}
