export interface RoutesGroup {
  All?: string;
  Entity?: (id?: number | string) => string;
  Custom?: (path: string[] | string) => string;
}

const BuildRoutesGroup = (root: string): RoutesGroup => {
  return {
    All: `${root}`,
    Entity: (id: number | string): string => `${root}/${id}`,
    Custom: (path: string | string[]) =>
      typeof path === "string" ? `${root}/${path}` : `${root}/${path.join("/")}`,
  };
};

export class APIRoutes {
  private readonly root = "https://api.guttmann-auta.com/api";

  public Apps: RoutesGroup = BuildRoutesGroup(`${this.root}/apps`);

  public Patients: RoutesGroup = BuildRoutesGroup(`${this.root}/patients/`);

  public Password: RoutesGroup = BuildRoutesGroup(`${this.root}/password`);

  public Sessions: RoutesGroup = BuildRoutesGroup(`${this.root}/apps/users/sessions`);

  public Tasks: RoutesGroup = BuildRoutesGroup(`${this.root}/tasks`);

  public TaskCategories: RoutesGroup = BuildRoutesGroup(`${this.root}/tasks/categories`);

  public Users: RoutesGroup = BuildRoutesGroup(`${this.root}/apps/users`);

  public Passwords: RoutesGroup = BuildRoutesGroup(`${this.root}/apps/password`);
}
