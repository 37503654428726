<h3 mat-dialog-title>Are you sure?</h3>
<div mat-dialog-content>
  <div class="py-4 flex flex-column">Are you sure you want to delete this information?</div>
</div>
<div mat-dialog-actions>
  <div class="pb-4 flex justify-content-end width-full">
    <button mat-flat-button color="light" class="btn cancel" (click)="onCancelClick()">
      CANCEL
    </button>
    <button mat-flat-button color="warn" [mat-dialog-close]="true" cdkFocusInitial>OK</button>
  </div>
</div>
