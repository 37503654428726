import * as R from "ramda";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APIRoutes } from "@models/api-routes.models";
import { forkJoin, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { DatatableCategoryData } from "@models/category.models";
import { BuildCategoriesFromRoot } from "@helpers/categories.helpers";

@Injectable({
  providedIn: "root",
})
export class CategoriesService {
  private readonly routes = new APIRoutes();

  public set storedSubCategoryPath(value: string[]) {
    localStorage.setItem("storedSubCategoryPath", value ? JSON.stringify(value) : "");
  }

  public get storedSubCategoryPath(): string[] {
    const storedSubCategoryPath = localStorage.getItem("storedSubCategoryPath");
    return storedSubCategoryPath ? (JSON.parse(storedSubCategoryPath) as string[]) : undefined;
  }

  public set storedCategory(value: DatatableCategoryData) {
    localStorage.setItem("storedCategory", value ? JSON.stringify(value) : "");
  }

  public get storedCategory(): DatatableCategoryData {
    const storedCategory = localStorage.getItem("storedCategory");
    return storedCategory ? (JSON.parse(storedCategory) as DatatableCategoryData) : undefined;
  }

  public set categoriesTree(value: { [key: string]: DatatableCategoryData }) {
    localStorage.setItem("categoryTree", value ? JSON.stringify(value) : "");
  }

  public get categoriesTree(): { [key: string]: DatatableCategoryData } {
    const tree = localStorage.getItem("categoryTree");
    return tree ? (JSON.parse(tree) as { [key: string]: DatatableCategoryData }) : undefined;
  }

  constructor(private readonly http: HttpClient) {}

  public list(): Observable<any> {
    return forkJoin([
      this.listByType("domain").pipe(map(BuildCategoriesFromRoot("domain"))),
      this.listByType("pathology").pipe(map(BuildCategoriesFromRoot("pathology"))),
      this.listByType("collective").pipe(map(BuildCategoriesFromRoot("collective"))),
      this.listByType("field").pipe(map(BuildCategoriesFromRoot("field"))),
    ]).pipe(
      map(R.mergeAll),
      tap((value: { [key: string]: DatatableCategoryData }) => (this.categoriesTree = value)),
      map(
        R.mapObjIndexed((o) => ({
          ...o,
          categories: R.pipe(R.propOr({}, "sub_categories"), R.keys, R.propOr(0, "length"))(o),
        })),
      ),
    );
  }

  public listByType(type: string): Observable<any> {
    return this.http.get<DatatableCategoryData[]>(this.routes.TaskCategories.Entity(type));
  }

  public store({
    name,
    type,
    category_id,
  }: {
    name: string;
    type: string;
    category_id?: number;
  }): Observable<void> {
    let payload: any = { name, type };
    if (category_id) {
      payload = { ...payload, category_id };
    }
    return this.http.post<void>(this.routes.TaskCategories.All, payload);
  }

  public update(id: number, { name, type }: { name: string; type: string }): Observable<void> {
    return this.http.put<void>(this.routes.TaskCategories.Entity(id), { name, type });
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(this.routes.TaskCategories.Entity(id));
  }
}
