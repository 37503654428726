import { DatatableCategoryData } from "@models/category.models";
import * as R from "ramda";

const getSubCategories = (data: DatatableCategoryData[]): DatatableCategoryData[] => {
  if (R.propOr(0, "length", data) === 0) {
    return [];
  }
  return R.pipe(
    R.pluck("sub_categories"),
    R.mergeAll,
    R.values,
    R.flatten,
    R.defaultTo([]),
    getSubCategories,
    R.concat(data),
    R.flatten,
  )(data);
};

const formatSubCategories = R.map(R.omit(["sub_categories"]));

const formatCategoriesTree = R.pipe(R.values, R.flatten);

export const GetFlattenCategories = R.pipe(
  formatCategoriesTree,
  getSubCategories,
  formatSubCategories,
);

const buildSubCategories = (o) =>
  R.over(
    R.lensProp("sub_categories"),
    R.pipe(R.defaultTo([]), R.map(buildSubCategories), R.indexBy(R.prop("id"))),
    o,
  );

export const BuildCategoriesFromRoot = (tag) =>
  R.pipe(R.objOf("sub_categories"), buildSubCategories, R.assoc("name", tag), R.objOf(tag));
